import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image"
import About from '../components/About'
import Button from '../components/Button'
import FeaturedQuiltBlock from '../components/FeaturedQuiltBlock'
import StaticQuiltBlocks from '../components/StaticQuiltBlocks'

const IndexPageTemplate = ({ title, content, feauturedImage }) => {
  const [scrollAmount, setScrollAmount] = useState(0)
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      setScrollAmount(window.scrollY)
    })
  }
  const scrollTop = function () {
    window && window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <section>
      <span 
        onClick={() => scrollTop()} 
        className={`${scrollAmount > 1000 ? '' : 'hidden'} z-50 text-sm font-bold md:hidden fixed right-0 bottom-0 mb-6 mr-6 cursor-pointer uppercase tracking-widest`}
      >
        Scroll to Top
      </span>
      {feauturedImage && (
        <div className="w-full h-full min-h-[50vh] relative overflow-hidden">
          <div className="absolute w-full h-full z-50">
            <GatsbyImage 
              className="h-full w-full object-cover" 
              imgClassName="h-full w-full object-cover" 
              image={feauturedImage.gatsbyImage} 
              loading="eager"
            />
          </div>
          <div className="absolute bg-red opacity-75 w-full h-full z-50"/>
          <div className="relative z-50 text-center w-full h-full flex flex-col items-center justify-center max-w-2xl mx-auto min-h-75vh p-8">
            <h1 className="h1 text-3xl md:text-6xl text-white mb-8">{title}</h1>
            <div
            className="text-white text-xl mb-8"
            dangerouslySetInnerHTML={{ __html: content }}
            />
            <Link
              to="/submit"
            >
              <Button alt>Submit Your Story</Button>
            </Link>
          </div>
        </div>
      )}
      <div className="max-w-screen-xl mx-auto md:my-20">
        <FeaturedQuiltBlock />
      </div>
      <div className="max-w-screen-xl mx-auto">
        <div className="md:mt-10 max-w-2xl p-8 mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">The Legacy Quilt</h2>
          <p className="text-lg">Explore some of the incredible stories of African Americans who have shaped our country’s culinary identity.</p>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <StaticQuiltBlocks />
      </div>
      <About />
    </section>
  )
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate
        feauturedImage={data.wpPage.featuredImage.node}
        title={data.wpPage.title}
        content={data.wpPage.content}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wpPage(databaseId: {eq: 33}) {
      title
      content
      featuredImage {
        node {
          gatsbyImage(layout: CONSTRAINED, width: 1200, height: 1200)
          altText
        }
      }
    }
  }
`
