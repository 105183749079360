import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Button from '../components/Button'

export const About = () => {

  const data = useStaticQuery(graphql`
      query {
        wpPage(databaseId: {eq: 33}) {
          title
          content
        }
      }
  `)
    
  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="md:my-12 md:mb-20 max-w-2xl p-8 mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">{data.wpPage.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
        <div className="mt-4">
          <a
            target="_blank" href="https://www.mofad.org/about-aa"
          >
              <Button>Learn More</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default About
