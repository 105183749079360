import React, { useState, useEffect } from 'react'
import { Sash } from './Sashes'

const Disclaimer = () => {
  const [show2, setShow2] = useState(false)

  return (
    <div>
      <div>
        <Sash className="absolute inset-0 z-1" />
      </div>
      <div className="disclaimer absolute inset-0">
        <div
          className={`disclaimer__text flex items-center p-4 ${show2 &&
            'show2'}`}
        >
          <p className="text-white text-xs p-4">
            The Museum of Food and Drink shares user submissions on an “as is”
            basis and makes no representations or warranties of any kind with
            respect to their contents. MOFAD assumes no responsibility for
            errors, inaccuracies, omissions, or any other inconsistencies
            herein.
          </p>
        </div>
        <div
          className={`disclaimer__icon absolute ${show2 && 'show2'}`}
          onMouseEnter={() => setShow2(true)}
          onMouseLeave={() => setShow2(false)}
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 35.5C27.66 35.5 35.5 27.66 35.5 18C35.5 8.33999 27.66 0.499992 18 0.499991C8.34 0.49999 0.500002 8.33999 0.500002 18C0.500001 27.66 8.34 35.5 18 35.5ZM16.25 9.24999L19.75 9.24999L19.75 12.75L16.25 12.75L16.25 9.24999ZM16.25 16.25L19.75 16.25L19.75 26.75L16.25 26.75L16.25 16.25Z"
              fill="#F15060"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
