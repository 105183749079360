import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackupImage from './BackupImage'
import usStates from '../data/USStates'
import searchIcon from '../img/search.svg'
import Disclaimer from './Disclaimer'
import { GatsbyImage } from "gatsby-plugin-image"

const states = Object.values(usStates)

const GridIcons = ({ view }) => (
  <div className='flex gap-1'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={view === 'grid-cols-1' ? 'opacity-100' : 'opacity-50'}>
      <rect width="24" height="24" fill="#F15060" />
    </svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={view === 'grid-cols-1' ? 'opacity-50' : 'opacity-100'}>
      <rect width="11" height="11" fill="#F15060"/>
      <rect y="13" width="11" height="11" fill="#F15060"/>
      <rect x="13" width="11" height="11" fill="#F15060"/>
      <rect x="13" y="13" width="11" height="11" fill="#F15060"/>
    </svg>
  </div>
)

const QuiltBlock = ({ quiltBlock }) => (
  <Link
    to={`quilt/${quiltBlock.node.slug}`}
    state={{
      modal: true,
    }}
    className="block relative aspect-square"
  >
    {quiltBlock.node.quiltBlockFields.quiltImage ? (
      <GatsbyImage
        className="object-cover w-full h-full"
        loading='lazy'
        alt={quiltBlock.node.quiltBlockFields.quiltImage.altText}
        image={quiltBlock.node.quiltBlockFields.quiltImage.gatsbyImage}
      />
    ) : (
      <BackupImage
        className="scale_inner__image w-full h-full object-cover pl-12 pb-12"
        category={quiltBlock.node.categories.nodes[0].slug}
      />
    )}
    {quiltBlock.node.quiltBlockFields.legacy === false ? (
      <Disclaimer />
    ):(
      <div className="absolute inset-0 bg-black opacity-0 hover:opacity-25 transition-opacity duration-150"></div>
    )}
  </Link>
)

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allWpQuiltBlock(filter: {title: {ne: "Empty"}}, sort: {fields: [quiltBlockFields___legacy, quiltBlockFields___locationRow, quiltBlockFields___locationColumn], order: ASC}) {
        edges {
          node {
            id
            title
            slug
            date(formatString: "MMMM DD, YYYY")
            categories {
              nodes {
                name
                slug
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            quiltBlockFields {
              century
              date
              city
              state
              description
              descriptionLong
              donor
              gender
              locationColumn
              locationRow
              legacy
              quiltImage {
                gatsbyImage(layout: CONSTRAINED, width: 400, height: 400)
                altText
              }
            }
          }
        }
        totalCount
      }
      allWpCategory {
        edges {
          node {
            databaseId
            name
          }
        }
      }
    }
  `)
  const quilt = data.allWpQuiltBlock.edges
  const cats = data.allWpCategory.edges
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [mobileView, setMobileView] = useState('grid-cols-2')
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }
  const toggleMobileView = () => {
    mobileView === 'grid-cols-1' ? setMobileView('grid-cols-2') : setMobileView('grid-cols-1')
  }
  const [searchOpen, setSearchOpen] = useState(false)
  const toggle = () => {
    setSearchOpen(!searchOpen)
    const searchinput = document.querySelector('#search')
    searchinput.focus()
  }
  const [searchTerm, setSearchTerm] = useState('')
  const [searchCategory, setSearchCategory] = useState('')
  const [searchSource, setSearchSource] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [searchTime, setSearchTime] = useState('')
  const [searchGender, setSearchGender] = useState('')
  const [searchSort, setSearchSort] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const clearAll = () => {
    const filterFields = document.querySelectorAll('.filter-field')
    filterFields.forEach(field => (field.value = 'All'))
    const sortField = document.querySelector('.sort-field')
    sortField.value = 'A to Z'
    setSearchTerm('')
    setSearchLocation('')
    setSearchCategory('')
    setSearchSource('')
    setSearchTime('')
    setSearchGender('')
    setSearchSort('A to Z')
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleLocation = event => {
    setSearchLocation(event.target.value)
  }

  const handleCategory = event => {
    setSearchCategory(event.target.value)
  }

  const handleSource = event => {
    setSearchSource(event.target.value)
  }

  const handleTime = event => {
    setSearchTime(event.target.value)
  }

  const handleSort = event => {
    setSearchSort(event.target.value)
  }

  const handleGender = event => {
    setSearchGender(event.target.value)
  }

  useEffect(() => {
    let results = quilt
    if (searchTerm !== '') {
      results = quilt.filter(
        quiltBlock => {
          const blockTags = quiltBlock.node.tags ? quiltBlock.node.tags.nodes.map(tag => tag.name) : ''
          const blockTagString = quiltBlock.node.tags ? blockTags.join(' ') : ''
          const sanitizedName = quiltBlock.node.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
          const sanitizedDonor = quiltBlock.node.quiltBlockFields?.donor ? quiltBlock.node.quiltBlockFields.donor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : ""
          return (
            sanitizedName.includes(searchTerm.toLowerCase()) ||
            sanitizedDonor.includes(searchTerm.toLowerCase()) ||
            blockTagString
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
        }
      )
    } else {
      results = quilt
        .filter(quiltBlock =>
          searchSource && searchSource !== 'All'
            ? (quiltBlock.node.quiltBlockFields.legacy && searchSource === "MOFAD Curated") || (!quiltBlock.node.quiltBlockFields.legacy && searchSource === "User Submitted")
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchCategory && searchCategory !== 'All'
            ? quiltBlock.node.categories.nodes[0].name === searchCategory
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchTime && searchTime !== 'All'
            ? quiltBlock.node.quiltBlockFields.century === searchTime
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchLocation && searchLocation !== 'All'
            ? quiltBlock.node.quiltBlockFields.state === searchLocation
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchGender && searchGender !== 'All'
            ? quiltBlock.node.quiltBlockFields.gender === searchGender
            : quiltBlock.node
        )
    }
    if (searchSort === 'Z to A') {
      setSearchResults(results.reverse())
    } else {
      setSearchResults(results)
    }
  }, [
    searchTerm,
    searchSource,
    searchCategory,
    searchTime,
    searchLocation,
    searchGender,
    searchSort,
  ])

  return (
    <div className="max-w-screen-xl mx-auto p-8 relative">
      <div className="md:hidden block sticky top-0 bg-white py-2 z-50 text-red">
        <div className='flex justify-between'>
          <button onClick={toggleFilters}>
            {filtersOpen ? 'Close Filters' : 'Filter & Search'}
          </button>
          <button aria-label='Toggle mobile grid view'  onClick={toggleMobileView}>
            <span>
              <GridIcons view={mobileView} />
            </span>
          </button>
        </div>
      </div>
      <div className={`${filtersOpen ? 'block filtersOpen' : 'hidden'} md:block sticky p-4 pb-8 bg-transparent md:m-0 md:h-auto h-auto top-0 md:top-0 left-0 z-50 md:bg-white md:px-0 md:pt-2 md:pb-2`}>
        <div className="flex flex-wrap justify-between items-center">
          <div>
            <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
              <select
                onChange={handleSource}
                className="filter-field"
                defaultValue="Source"
              >
                <option disabled>Source</option>
                <option>All</option>
                <option>MOFAD Curated</option>
                <option>User Submitted</option>
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
              <select
                onChange={handleCategory}
                className="filter-field"
                defaultValue="Category"
              >
                <option disabled>Category</option>
                <option>All</option>
                {cats.map(cat => (
                  <option key={cat.node.id}>{cat.node.name}</option>
                ))}
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
              <select
                onChange={handleLocation}
                className="filter-field"
                defaultValue="Location"
              >
                <option disabled>Location</option>
                <option>All</option>
                {states.map(us => (
                  <option key={us} value={us}>
                    {us}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
              <select
                onChange={handleTime}
                className="filter-field"
                defaultValue="Time Period"
              >
                <option disabled>Time Period</option>

                <option>All</option>
                <option>1619-1718</option>
                <option>1719-1818</option>
                <option>1819-1918</option>
                <option>1919-Present</option>
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
              <select
                onChange={handleGender}
                className="filter-field"
                defaultValue="Pronouns"
              >
                <option disabled>Pronouns</option>

                <option>All</option>
                <option>She/Her</option>
                <option>He/Him</option>
                <option>They/Them</option>
                <option>N/A</option>
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className="inline-block cursor-pointer absolute md:relative bottom-0 right-0 p-2 md:p-0">
              <span
                className="text-gray-500 cursor-pointer ml-5 underline"
                onClick={clearAll}
              >
                clear filters
              </span>
            </div>
          </div>

          <div className="flex flex-col w-full md:w-auto md:flex-row items-center">
            <div className="relative w-full mb-1 md:mb-0 md:w-auto">
              <select
                onChange={handleSort}
                className="sort-field"
                defaultValue="A to Z"
              >
                <option>A to Z</option>
                <option>Z to A</option>
              </select>
              <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className="search__container relative w-full mb-1 md:mb-0 md:w-auto">
              <div onClick={toggle} className="ml-1 p-1 hidden md:block cursor-pointer hover:opacity-50">
                <img
                  className="search__icon"
                  src={searchIcon}
                  alt="upload"
                  style={{ width: '18px', height: '18px' }}
                  loading="lazy"
                />
              </div>
              <div
                className={`search__bar p-2 bg-neutral md:absolute w-full mb-1 md:mb-0 md:w-auto ${
                  searchOpen ? 'search__bar--show' : ''
                }`}
              >
                <input
                  className="uppercase bg-transparent text-gray-900"
                  id="search"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!searchResults.length && 
        <div className="bg-neutral col-span-full p-8 text-center border-neutral border-2 rounded-sm w-full">
          <span className="block mb-2">No results...</span>
          <span onClick={clearAll} className="block underline">Clear Filters & Search</span>
        </div>
      }
      <div className={`grid md:grid-cols-3 ${mobileView}`}>
        {searchResults.length && searchResults.map(quiltBlock => (
          <QuiltBlock
            key={quiltBlock.node.id}
            quiltBlock={quiltBlock}
          />
        ))}
      </div>
    </div>
  )
}
